import { INotice, Notifier } from "@airbrake/browser"
import Bowser from "bowser"

import { ENVIRONMENT, IS_DEVELOPMENT, IS_TEST } from "./environment"

// `INoticeError` isn't exported from package so we need to derive it
type INoticeError = NonNullable<INotice["errors"]>[number]

// from config/initializers/airbrake.rb
const PROJECT_ID = 37_617
const PROJECT_KEY = "f5eaf963bfcfe86e41acb8184bc4bea5"

const ignoredErrors = [
  /^document.getElementsByClassName.ToString is not a function/,
  /^Blocked a frame with origin/,
  /^Calculating structure twice ! Should not happen/,
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/29
  /^Object Not Found Matching Id/,
  /^(cancelled|annulé|cancelado)$/, // Safari throws this on aborted fetch request
  /^undefined is not an object \(evaluating '__gCrWeb.edgeTranslate.detectPageState'\)$/ // Ignore GCR errors
]

const ignoredFilePrefixes = [
  "chrome-extension://",
  "duckduckgo-privacy-protection.js",
  "file:///Applications/Honey.app",
  "HoneyError",
  "https://config1.veinteractive.com",
  "https://home-l32.niceincontact.com/inContact/ChatClient/js/embed.min.js",
  "safari-extension://",
  "safari-web-extension://",
  "webkit-masked-url://hidden"
]

// Ignore bots not detected by `bowser`
const isBot = (notice: INotice): boolean => {
  const bots = ["AdsBot-Google-Mobile"]
  return bots.some((bot) => notice.context.userAgent.includes(bot))
}

const ignoreError = (error: INoticeError): boolean => {
  for (const ignored of ignoredErrors) {
    if (ignored.test(error.message)) {
      return true
    }
  }

  return false
}

const isUsingSupportedBrowser = (): boolean => {
  const browser = Bowser.getParser(window.navigator.userAgent)

  const isInvalidBrowser = browser.satisfies({
    googlebot: ">=0",
    ie: ">=0",
    opera: ">=0"
  })

  if (isInvalidBrowser) {
    return false
  }

  // E.g. https://browsersl.ist/#q=last+2+major+versions%2C+not+dead
  const isValidBrowser = browser.satisfies({
    android: ">=131",
    chrome: ">=130",
    edge: ">=130",
    firefox: ">=132",
    safari: ">=17.6",
    samsung_internet: ">=26"
  })

  // Default to `true` if browser not found, just to catch any weirdness
  return isValidBrowser === undefined ? true : isValidBrowser
}

const ignoreFile = (error: INoticeError): boolean => {
  for (const ignored of ignoredFilePrefixes) {
    for (const line of error.backtrace) {
      if (line.file.indexOf(ignored) === 0) {
        return true
      }
    }
  }
  return false
}

const globalCodeError = (error: INoticeError): boolean => {
  return error.backtrace.length === 1 && error.backtrace[0].function === "global code"
}

const airbrakeClient = new Notifier({
  environment: ENVIRONMENT || "production",
  projectId: PROJECT_ID,
  projectKey: PROJECT_KEY,
  remoteConfig: false
})

airbrakeClient.addFilter((notice: INotice): INotice | null => {
  const error = notice.errors && notice.errors[0]
  if (!error) {
    return null
  }

  if (isBot(notice)) {
    return null
  }
  // Do not report blog errors, we don't care about them.
  if (window.location.pathname.startsWith("/blog/")) {
    return null
  }
  if (ignoreError(error)) {
    return null
  }
  if (!isUsingSupportedBrowser()) {
    return null
  }
  if (ignoreFile(error)) {
    return null
  }
  if (globalCodeError(error)) {
    return null
  }

  if (IS_DEVELOPMENT || IS_TEST) {
    console.log(notice) // eslint-disable-line no-console
    return null
  } else {
    return notice
  }
})

export const logDecodingError = (flags: Flags): void => {
  void airbrakeClient.notify({
    error: `Error decoding flags or payload at ${window.location.href}`,
    params: flags
  })
}

export default airbrakeClient
